import { type ReactNode } from 'react';
import { LoadingTransactionRow } from '@repo/domain-kit/cashflow';
import { BalanceCell, BaseTable } from '@repo/domain-kit/shared';
import { type ColumnDef } from '@tanstack/react-table';
import { SubscriptionSupplier } from './cells/subscription-supplier';

// ToDo: remove the dummy data after codegen process is done
const subscriptions = [
  {
    id: '0195ef0f-70cc-7667-a924-ca56cb6b92cd',
    counterpartyName: 'Decathlon',
    amount: {
      value: '5.00',
      currency: 'EUR',
    },
    frequency: 'FREQUENCY_MONTHLY',
    nextPaymentDate: '2025-04-24',
    paymentMethod: 'PAYMENT_METHOD_CARD',
    logoURL:
      'https://qonto-assets-staging-cbs-staging.s3.eu-west-3.amazonaws.com/customized-brand-logos/qonto-api/63ddeba4-b89f-4c4f-bd0a-54eaff8a213f/small.png',
    bankAccount: {
      name: 'Compte principal',
      logo: {
        small:
          'https://qonto-assets-staging-cbs-staging.s3.eu-west-3.amazonaws.com/customized-brand-logos/qonto-api/63ddeba4-b89f-4c4f-bd0a-54eaff8a213f/small.png',
        medium:
          'https://qonto-assets-staging-cbs-staging.s3.eu-west-3.amazonaws.com/customized-brand-logos/qonto-api/63ddeba4-b89f-4c4f-bd0a-54eaff8a213f/medium.png',
      },
    },
    recentTransactions: [
      {
        id: '01955ffd-8da7-767e-a780-32c750b123e7',
        emittedAt: '2025-03-12T14:22:35.701Z',
        amount: {
          value: '1170.00',
          currency: 'EUR',
        },
        card: {
          id: '01955ffd-8da7-767e-a780-32c750b271d1',
          nickname: 'Card 1',
          last4: '1234',
          cardLevel: 'METAL',
          holder: {
            id: '01955ffd-8da7-767e-a780-32c750b271d1',
            fullName: 'John Doe',
          },
        },
      },
      {
        id: '01955ffd-8da7-767e-a780-32c750b123e7',
        emittedAt: '2025-03-12T14:22:35.701Z',
        amount: {
          value: '1170.00',
          currency: 'EUR',
        },
        card: {
          id: '01955ffd-8da7-767e-a780-32c750b271d1',
          nickname: 'Card 1',
          last4: '1234',
          cardLevel: 'METAL',
          holder: {
            id: '01955ffd-8da7-767e-a780-32c750b271d1',
            fullName: 'John Doe',
          },
        },
      },
    ],
  },
];

// Types will be updated after we get the types and definitions from the GraphQL codegen
export const columns: ColumnDef<unknown>[] = [
  {
    accessorKey: 'counterpartyName',
    header: 'Supplier',
    enableSorting: true,
    // @ts-expect-error - to be updated when we get the generated types
    cell: info => <SubscriptionSupplier supplierInfo={info} />,
  },
  {
    accessorKey: 'nextPaymentDate',
    header: 'Next payment',
    enableSorting: true,
    // @ts-expect-error - to be updated when we get the generated types
    cell: info => <div>{info.row.original.nextPaymentDate}</div>,
  },
  {
    accessorKey: 'paymentMethod',
    header: 'Payment method',
    // @ts-expect-error - to be updated when we get the generated types
    cell: info => <div>{info.row.original.paymentMethod}</div>,
  },
  {
    accessorKey: 'frequency',
    header: 'Frequency',
    // @ts-expect-error - to be updated when we get the generated types
    cell: info => <div>{info.row.original.frequency}</div>,
  },
  {
    accessorKey: 'amount.value',
    header: 'Amount',
    enableSorting: true,
    cell: () => (
      <BalanceCell
        amount={{
          value: 1000,
          currency: 'EUR',
        }}
        displayCurrencySymbol
        displaySign={false}
      />
    ),
  },
];

export function SupplierSubscriptionsTable(): ReactNode {
  return (
    <div data-testid="supplier-subscriptions-table">
      <BaseTable
        columns={columns}
        data={subscriptions}
        enablePagination
        isLoading={false}
        loadingComponent={LoadingTransactionRow()} // ToDo: add loading component
        pageSize={25}
        pageSizes={[25, 50, 100]}
        total={subscriptions.length}
      />
    </div>
  );
}

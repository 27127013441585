import { Counterparty } from '@repo/domain-kit/shared';
import { type CellContext } from '@tanstack/react-table';
import { type ReactNode } from 'react';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';

interface SupplierInfo {
  counterpartyName: string;
  logoURL?: string;
}

export function SubscriptionSupplier({
  supplierInfo,
}: {
  supplierInfo: CellContext<SupplierInfo, unknown>;
}): ReactNode {
  const supplier = supplierInfo.row.original.counterpartyName;
  const imageUrl = supplierInfo.row.original.logoURL;

  return (
    <BaseCell data-testid="subscription-supplier">
      <Counterparty imageUrl={imageUrl} title={supplier} />
    </BaseCell>
  );
}
